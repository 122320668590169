<template>
  <div>
    <base-header type="" class="pt-5 pt-md-8"> </base-header>

    <!--Tables-->
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-xl-12 mb-5 mb-xl-2">
          <div class="d-flex justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <card shadow shadowSize="sm" headerClasses="text-center">
                <div slot="header">
                  <h2 class="mb-0">Payment Methods</h2>
                  <sub class="text-muted"
                    >Only Available for Ugandan payments</sub
                  >
                </div>
                <slot>
                  <div
                    class="card-body text-center text-muted bg-white"
                    v-if="this.methods.length == 0"
                  >
                    No payments intergration yet!
                  </div>
                  <div
                    class="
                      card-body
                      shadow shadow-sm
                      card-lift--hover
                      bg-white
                      mb-2
                    "
                    v-for="(item, index) in this.methods"
                    :key="index"
                    v-else
                    style="padding: 0.8rem !important; cursor: pointer"
                  >
                    <div class="d-flex flex-row flex-wrap align-items-center">
                      <div
                        class="
                          col-2
                          d-flex
                          justify-content-start
                          align-items-center
                        "
                      >
                        <img
                          :src="`/img/brand/payments/${item.slug}.png`"
                          height="40"
                          type="image/png"
                        />
                      </div>
                      <div
                        class="
                          col-5
                          d-flex
                          justify-content-start
                          pl-2
                          align-items-center
                        "
                      >
                        <h2>{{ item.name }}</h2>
                      </div>
                      <div
                        class="
                          col-4
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                      >
                        <span
                          ><small>Available: </small
                          ><i
                            :class="`fa px-2 ${
                              item.status
                                ? 'fa-check-circle text-green'
                                : 'fa-times-circle text-gray'
                            }`"
                          ></i
                        ></span>
                      </div>
                      <div class="col d-flex justify-content-center">
                        <div class="d-flex align-items-center">
                          <i class="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </slot>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End tables-->
  </div>
</template>
<script>
import Vue from "vue";
import MD5 from "crypto-js/md5";
import Card from "../components/Card.vue";
// Tables
export default {
  components: { Card },
  data() {
    return {
      amount: null,
      userPhone: null,
    };
  },
  computed: {
    methods() {
      return this.$store.state.topups?.paymentMethods?.methods ?? [];
    },
    key (){
      return this.$store.state.topups.paymentMethods != null
        ? Vue.decrypt(
            process.env.VUE_APP_ENCRYPTION_KEY || "",
            process.env.VUE_APP_IV_KEY || "",
            JSON.parse(
              window.atob(this.$store.state.topups.paymentMethods.keys)
            )
          )["flutterwave"]
        : "Loading ..."
    },
    flutterwaveData() {
      return {
        public_key: this.key,
        tx_ref: this.generateReference(),
        amount: this.amount,
        currency: "UGX",
        payment_options: "card, ussd, mobilemoney",
        redirect_url: "",
        meta: {
          consumer_id: this.$store.state.profile?.me?.id ?? "",
          consumer_mac: MD5(
            this.$store.state.profile?.me?.name ?? ""
          ).toString(),
        },
        customer: {
          name: this.$store.state.profile?.me?.name ?? "Dummy Name",
          email: this.$store.state.profile?.me?.email ?? "Dummy Email",
          phone_number: this.$store.state.profile?.me?.phone ?? this.userPhone,
        },
        customizations: {
          title: "VerifyKYC : Purchase Credits",
          description: "Purchasing credits worth Ush. " + this.amount,
          logo: "https://verify-kyc.com/img/brand/green.png",
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
      };
    },
  },
  methods: {
    makePaymentCallback(response) {
      console.log("Pay: ", response);
    },
    closedPaymentModal() {
      console.log("Payment Module Session Closed");
    },
    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },
    flutterwave() {
      return this.flutterwaveData;
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/me");
    await this.$store.dispatch("topups/getPaymentMethods");
  },
  async created() {},
};
</script>
<style></style>
