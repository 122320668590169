<template>
  <div>
    <base-header type="" class="pt-5 pt-md-8"> </base-header>

    <!--Tables-->
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-xl-12 mb-5 mb-xl-2">
          <expenditures-table></expenditures-table>
        </div>
      </div>
    </div>
    <!--End tables-->
  </div>
</template>
<script>
// Tables
import ExpendituresTable from "./Dashboard/Tables/ExpendituresTable";
import inactivityMixin from '@/mixins/inactivity-mixin'

export default {
  components: {
    ExpendituresTable,
  },
  mixins: [inactivityMixin]
};
</script>
<style>
</style>
