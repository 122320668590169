<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Expenditure</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table

        thead-classes="thead-light"
        :data="tableData.data"
        :nodataspan="4"
      >
        <template slot="columns">
          <th>Request ID</th>
          <th>Description</th>
          <th>Date</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            {{ row.request_id }}
          </th>
          <td>
            {{ row.description }}
          </td>
          <td>

            {{
              (row.created_at == row.updated_at
                ? row.created_at
                : row.updated_at
              )
                .split("T")
                .join(" ")
                .split(".")[0]
            }}
          </td>
        </template>
      </base-table>
    </div>


    <div class="mt-3 mx-3">
      <base-pagination
        :total="tableData.total"
        :perPage="this.perPage"
        :value="this.page"
        :fro="tableData.from"
        :to="tableData.to"
        @input="changePage"
        @change="changePerPage"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import BasePagination from "../../../components/BasePagination.vue";
export default {
  components: { BasePagination},
  name: "expenditures-table",
  data() {
    return {
      tableData: {
        data: []
      },
      perPage: 10,
      page: 1,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("expenditure/index", {
      page: 1,
      perPage: this.perPage,
    });
    this.tableData = this.$store.state.expenditure.data;
    this.loading = false;
  },
  methods: {
    async changePage(page) {
      this.loading = true;
      await this.$store.dispatch("expenditure/index", {
        page: page,
        perPage: this.perPage,
      });

      this.tableData = this.$store.state.expenditure.data;
      this.page = page;

      this.loading = false;
    },
    async changePerPage(pp) {
      this.loading = true;
      await this.$store.dispatch("expenditure/index", {
        page: this.page,
        perPage: pp,
      });

      this.tableData = this.$store.state.expenditure.data;
      this.perPage = pp;

      if (this.page > this.tableData.last_page) {
        this.page = this.tableData.last_page;
        await this.$store.dispatch("expenditure/index", {
          page: this.page,
          perPage: pp,
        });
        this.tableData = this.$store.state.expenditure.data;
        this.loading = false;
      } else this.loading = false;
    },
  },
};
</script>
<style></style>
