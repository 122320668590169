<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Top Ups</h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        thead-classes="thead-light"
        :data="tableData.data"
        :nodataspan="6"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Status</th>
          <th>Payment Method</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Date</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            {{ row.id }}
          </th>
          <td>
            <badge type="success" icon="fa fa-check" circle v-if="row.status == 'success'"><i class="fa fa-check"></i></badge>
            <badge type="warning" icon="fa fa-refresh" circle v-else-if="row.status == 'pending'"><i class="fa fa-sync fa-spin"></i></badge>
            <badge type="danger" icon="fa fa-times" circle v-else><i class="fa fa-times"></i></badge>
          </td>
          <td>
            {{ row.payment_method.name }}
          </td>
          <td>
            {{ row.description }}
          </td>
          <td>
            {{ row.amount }}
          </td>
          <td>
            {{
              (row.created_at == row.updated_at
                ? row.created_at
                : row.updated_at
              )
                .split("T")
                .join(" ")
                .split(".")[0]
            }}
          </td>
        </template>
      </base-table>
    </div>

    <div class="mx-3 mt-3">
      <base-pagination
        :total="tableData.total"
        :perPage="this.perPage"
        :value="this.page"
        :fro="tableData.from"
        :to="tableData.to"
        @input="changePage"
        @change="changePerPage"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import Badge from '../../../components/Badge.vue';
import BasePagination from "../../../components/BasePagination.vue";
export default {
  components: { BasePagination, Badge },
  name: "top-ups-table",
  data() {
    return {
      tableData: [
        // {
        //id: 12,
        //status: 'pending',
        //date: "02/02/2022",
        //description: "Flutterwave Payment",
        //paymentMethod: 'flutterwave',
        //amount: "Ush. 20,000",
        //balance: 5000,
        // },
      ],
      perPage: 10,
      page: 1,
      loading: false,
    };
  },
  async created() {
    await this.$store.dispatch("topups/topups", {
      page: 1,
      perPage: this.perPage,
    });
    this.tableData = this.$store.state.topups.data;
    console.log(this.tableData);
  },
  methods: {
    async changePage(page) {
      await this.$store.dispatch("topups/topups", {
        page: page,
        perPage: this.perPage,
      });

      this.tableData = this.$store.state.topups.data;
      this.page = page;
    },
    async changePerPage(pp) {
      await this.$store.dispatch("topups/topups", {
        page: this.page,
        perPage: pp,
      });

      this.tableData = this.$store.state.topups.data;
      this.perPage = pp;
      if (this.page > this.tableData.last_page) {
        this.page = this.tableData.last_page;
      await this.$store.dispatch("topups/topups", {
        page: this.page,
        perPage: pp,
      });
      }
    },
  },
};
</script>
<style></style>
